import axios from "axios";
import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import MUIDataTable from "mui-datatables";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Swal from "sweetalert2";
import NewLot from "./NewLot";
import EditLot from "./EditLot";
import { Link } from "react-router-dom";
import GavelIcon from '@mui/icons-material/Gavel';
import "./Lot.css"

function Lotsdetails({ fetchAuctions }) {
  const { id } = useParams();
  const [lotdata, setLotdata] = useState("");
  const [lots, setLots] = useState([]);
  const [openlot, setOpenlot] = useState(false);
  const [openeditlot, setOpeneditlot] = useState(false);
  const handleCloselot = () => setOpenlot(false);
  const handleCloseeditlot = () => setOpeneditlot(false);
  const [pagination, setPagination] = useState({ currentPage: 1, lastPage: 1 });
  const [perPage, setPerPage] = useState(10);

  //----------------------------lots---------------------------------------
  const fetchLots = useCallback(
    async (page = 1, perPage = 10) => {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/list/auctions/lot/${id}?page=${page}&per_page=${perPage}`,
          { headers }
        );

        // console.log(response.data, "response")
        const { data, current_page, last_page } = response.data.lots;
        setLots(data);
        setPagination({ currentPage: current_page, lastPage: last_page });
      } catch (error) {
        console.error("Error fetching auctions:", error);
      }
    },
    [id]
  );

  useEffect(() => {
    fetchLots();
  }, [fetchLots]);

  //   const mockLots = lots;

  const openLotModal = () => {
    setOpenlot(true);
  };

  const openEditLotModal = (value) => {
    setLotdata(value);
    setOpeneditlot(true);
  };

  const columns = [
    {
      name: "number",
      label: "Lot",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "estimate_from",
      label: "Estimate From",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "estimate_to",
      label: "Estimate To",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "hash_id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <EditIcon
                fontSize="small"
                sx={{ color: "green", cursor: "pointer" }}
                onClick={() => openEditLotModal(value)}
              />
              <DeleteIcon
                fontSize="small"
                sx={{ color: "red", cursor: "pointer" }}
                onClick={() => handleDelete(value)}
              />
            </>
          );
        },
      },
    },
    {
      name: "hash_id",
      label: "Bidders",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <div className="d-flex flex-wrap align-items-center">
                <Link
                  to={`/lot/bid_sataus/${value}`}
                  style={{ textDecoration: "none" }}
                >
                  <p
                    style={{
                      color: "#2695FF",
                      fontSize: "15px",
                      cursor: "pointer",
                      boxShadow: "#0000001a 0px 8px 24px",
                      fontWeight: "500",
                    }}
                    className="mb-0 p-2"
                  >
                   <GavelIcon style={{ fontSize: "22px" }} /> View
                  </p>
                </Link>
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
    download: false,
    print: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: "No bid found",
      },
    },
  };

  /*------------Delete LOT-------------*/
  const handleDelete = async (hash_Id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found.");
          return;
        }
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/auctions/lot/delete/${hash_Id}`,
            { headers }
          )
          .then((response) => {
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            fetchLots();
          });
        // console.log(`Auction with hashId ${hash_Id} deleted successfully`);
      }
    } catch (error) {
      console.error("Error deleting auction:", error.message);
    }
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      fetchAuctions(pagination.currentPage - 1, perPage);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      fetchAuctions(pagination.currentPage + 1, perPage);
    }
  };

  return (
    <>
      <section className="lots-section" id="lots">
        <div className="container">
          <div className="row">
            <div className="d-flex justify-content-end align-items-center mb-3">
              <button
                className="btn btn-primary mt-0"
                onClick={() => openLotModal()}
              >
                <AddIcon style={{ fontSize: "25px" }} />
                New Lot
              </button>
            </div>
            <MUIDataTable
              title={"Auction Lots"}
              data={lots}
              columns={columns}
              options={options}
            />
            <div className="d-flex justify-content-end align-items-center pagination">
              <div className="mx-3">
                <label htmlFor="perPage">Rows per page: </label>
                <select
                  id="perPage"
                  value={perPage}
                  onChange={(e) => {
                    setPerPage(Number(e.target.value));
                    fetchAuctions(1, Number(e.target.value));
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div>

              {pagination && (
                <div className="d-flex align-items-center pagi">
                  <button
                    onClick={handlePreviousPage}
                    disabled={pagination.currentPage === 1}
                    cursor="pointer"
                  >
                    <ArrowBackIosIcon />
                  </button>
                  <span className="font-semibold ">
                    {pagination.currentPage} of {pagination.lastPage}
                  </span>
                  <button
                    onClick={handleNextPage}
                    disabled={pagination.currentPage === pagination.lastPage}
                    cursor="pointer"
                  >
                    <ArrowForwardIosIcon />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <NewLot
        open={openlot}
        handleClose={handleCloselot}
        fetchLots={fetchLots}
        id={id}
      />
      <EditLot
        open={openeditlot}
        handleClose={handleCloseeditlot}
        lotdata={lotdata}
        fetchLots={fetchLots}
      />
    </>
  );
}

export default Lotsdetails;
