import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";

function Mailform({ handleClose, open, rowsid }) {
  const [isSubmitting, setIsSubmitting] = useState("");

  // Log rowid only when it changes
  useEffect(() => {
    if (rowsid) {
      // console.log("row id", rowsid);
    }
  }, [rowsid]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
  };

  const validationSchema = Yup.object().shape({
    subject: Yup.string().required("subject is required"),
    message: Yup.string().required("message is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      subject: "",
      message: "",
      id: rowsid,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log("values", values);
      setIsSubmitting(true);
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/admin/send-bulk-email`,
          values,
          { headers }
        )
        .then((result) => {
          // console.log(result);
          toast.success("Email sent successfully");
          setIsSubmitting(false);
          formik.resetForm();
          handleClose();
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false);
          toast.error("Something went wrong", error);
        });
    },
  });

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="d-grid p-4 auctionnew-section">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <h2 style={{ color: "#2695FF" }}>Mass Mail</h2>
              <button
                type="button"
                class="close text-end"
                onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex align-items-center mb-3 mt-2">
                <label>Subject </label>
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="subject"
                    name="subject"
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.subject && formik.errors.subject && (
                    <p className="err mb-0">{formik.errors.subject}</p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3 mt-2">
                <label>Message </label>
                <div className="w-100">
                  <textarea
                    rows={3}
                    placeholder="message"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                  {formik.touched.message && formik.errors.message && (
                    <p className="err mb-0">{formik.errors.message}</p>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}>
                  {!isSubmitting ? "Send Email" : "Sending Email..."}
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Mailform;
