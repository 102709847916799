import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "./Newlot.css";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import * as Yup from "yup";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function EditLot({ handleClose, open, lotdata, fetchLots }) {
  const [isSubmitting, setIsSubmitting] = useState("");
  const [categoryType, setCategoryType] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [lot, setLotdata] = useState("");
  const [images, setImages] = useState([]);

  // console.log(lotdata, "lotdata")

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
  };

  // useEffect(() => {
  // if (lotdata) {
  const fetchlotdata = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/auctions/lot/edit/${lotdata}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        setLotdata(response.data.lot);
        // console.log(response.data.lot.auction_images, "exist image")
      })
      .catch((error) => {
        console.error("Error fetching lot data:", error);
      });
  };
  // }
  // }, []);

  useEffect(() => {
    if (lotdata) {
      fetchlotdata();
    }
  }, [lotdata]);

  const handleRemoveExistingImage = async (image, index) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get/deleteimg/${image.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      if (response.status === 200) {
        const updatedImages = (formik.values.auction_images || []).filter(
          (img) => img.id !== image.id
        );
        formik.setFieldValue("images", updatedImages);
        fetchlotdata();
      } else {
        console.error("Failed to delete image:", response.data);
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleNewImageUpload = (event) => {
    const files = Array.from(event.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
    formik.setFieldValue("images", [...images, ...files]);
  };

  const handleRemoveNewImage = (indexToRemove) => {
    const updatedImages = images.filter((_, index) => index !== indexToRemove);
    setImages(updatedImages);
    formik.setFieldValue("images", updatedImages);
  };

  useEffect(() => {
    // Fetch categories from the API
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/categories`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch categories");
        }
        const data = await response.json();
        if (Array.isArray(data.data)) {
          setCategoryType(data.data);
          const initialBiddingType = data.data.find(
            (type) => type.name === lot?.categories[0]?.name
          );
          if (initialBiddingType) {
            setSelectedCategoryId(initialBiddingType.hash_id);
            formik.setFieldValue("category_id", initialBiddingType.hash_id);
          }
        } else {
          console.error("Expected an array but received:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchCategories();
  }, [lot]);

  const handleCategoryChange = (event) => {
    const selectedId = event.target.value;
    setSelectedCategoryId(selectedId);
    // fetchStates(selectedId);
    const selectedType = categoryType?.find(
      (type) => type.hash_id === selectedId
    );
    if (selectedType) {
      // setSelectedCountryType(selectedType.name);
    }
    formik.setFieldValue("category_id", selectedId);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    estimate_from: Yup.string().required("Estimate from is required"),
    estimate_to: Yup.string().required("Estimate to is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: lot?.title || "",
      description: lot?.description || "",
      estimate_from: lot?.estimate_from || "",
      estimate_to: lot?.estimate_to || "",
      reserve_price: lot?.reserve_price || "",
      lot_meta_title: lot?.lot_meta_title || "",
      lot_meta_description: lot?.lot_meta_description || "",
      auction_id: lot?.auction_id || "",
      category_id:
        (lot?.categories &&
          Array.isArray(lot.categories) &&
          lot.categories[0]?.hash_id) ||
        [],
      images: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(values, "form values");
      setIsSubmitting(true);
      const formData = new FormData();
      // Append form values
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("estimate_from", values.estimate_from);
      formData.append("estimate_to", values.estimate_to);
      formData.append("category_id", values.category_id);
      formData.append("auction_id", values.auction_id);
      formData.append("lot_meta_title", values.lot_meta_title);
      formData.append("lot_meta_description", values.lot_meta_description);
      formData.append("reserve_price", values.reserve_price);
      // formData.append("images", values.auction_images);

      values.images.forEach((file, index) => {
        formData.append(`images[${index}]`, file);
      });

      try {
        const accessToken = localStorage.getItem("access_token");
        const apiHeaders = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        };
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/updates/auctions/lot/${lotdata}`,
            formData,
            { headers: apiHeaders }
          )
          .then((response) => {
            // console.log(response, "response");
            setIsSubmitting(false);
            handleClose();
            setImages([])
            fetchLots();
            toast.success("Lot Updated Successfully!");
          });
      } catch (error) {
        console.error("Error lot auction:", error);
        setIsSubmitting(false);
        toast.error("Error lot auction:", error);
      }
    },
  });

  // useEffect(() => {
  //   if (formik.errors && Object.keys(formik.errors).length > 0) {
  //     console.log("Validation Errors:", formik.errors);
  //   }
  // }, [formik.errors]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="d-grid p-4 lotdetail-section"
            style={{ overflowY: "scroll", height: "500px" }}
          >
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <h2 style={{ color: "#2695FF" }}>Edit Lot</h2>
              <button
                type="button"
                class="close text-end"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex align-items-center mb-3">
                <label>Lot Title: </label>
                <div className="d-grid  w-100">
                  <input
                    type="text"
                    placeholder="Title"
                    name="title"
                    id="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <p className="err mb-0">{formik.errors.title}</p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Lot Description: </label>
                <div className="d-grid  w-100">
                  <textarea
                    placeholder="Lot description"
                    rows={3}
                    name="description"
                    id="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>

                  {formik.touched.description && formik.errors.description && (
                    <p className="err mb-0">{formik.errors.description}</p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Estimate From: </label>
                <div className="d-grid  w-100">
                  <input
                    type="text"
                    placeholder="Estimate from"
                    name="estimate_from"
                    id="estimate_from"
                    value={formik.values.estimate_from}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.estimate_from &&
                    formik.errors.estimate_from && (
                      <p className="err mb-0">{formik.errors.estimate_from}</p>
                    )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Estimate To: </label>
                <div className="d-grid  w-100">
                  <input
                    type="text"
                    placeholder="Estimate to"
                    name="estimate_to"
                    id="estimate_to"
                    value={formik.values.estimate_to}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.estimate_to && formik.errors.estimate_to && (
                    <p className="err mb-0">{formik.errors.estimate_to}</p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Reserve Price: </label>
                <div className="d-grid  w-100">
                  <input
                    type="text"
                    placeholder="Reserve price"
                    name="reserve_price"
                    id="reserve_price"
                    value={formik.values.reserve_price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.reserve_price &&
                    formik.errors.reserve_price && (
                      <p className="err mb-0">{formik.errors.reserve_price}</p>
                    )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Category: </label>
                <div className="d-grid  w-100">
                  <select
                    value={selectedCategoryId}
                    onChange={handleCategoryChange}
                    onBlur={formik.handleBlur}
                    name="category"
                    id="category"
                    className="w-100 border-1 p-3"
                  >
                    {Array.isArray(categoryType) &&
                      categoryType.map((type) => (
                        <option key={type.hash_id} value={type.hash_id}>
                          {type.name}
                        </option>
                      ))}
                  </select>
                  {formik.touched.category && formik.errors.category && (
                    <p className="err mb-0">{formik.errors.category}</p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Lot Meta Title: </label>
                <input
                  type="text"
                  placeholder="Lot meta title"
                  name="lot_meta_title"
                  id="lot_meta_title"
                  value={formik.values.lot_meta_title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Lot Meta Description: </label>
                <textarea
                  placeholder="Lot meta description"
                  rows={3}
                  name="lot_meta_description"
                  id="lot_meta_description"
                  value={formik.values.lot_meta_description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></textarea>
              </div>

              <div className="">
                <h4>Existing Images</h4>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {(lot.auction_images || []).map((image, index) => (
                    <div
                      key={`existing-image-${index}`}
                      style={{
                        position: "relative",
                        marginRight: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${image.image_path}`}
                        alt={`existing-lot-image-${index}`}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        loading="lazy"
                      />
                      {/* Remove button for existing images */}
                      <button
                        type="button"
                        onClick={() => handleRemoveExistingImage(image, index)}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          background: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "24px",
                          height: "24px",
                          cursor: "pointer",
                        }}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <h4>Upload New Images</h4>
              <div style={{ marginBottom: "16px" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {images.map((image, index) => (
                    <div
                      key={`new-image-${index}`}
                      style={{
                        position: "relative",
                        marginRight: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`new-lot-image-${index}`}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                      {/* Remove button for new images */}
                      <button
                        type="button"
                        onClick={() => handleRemoveNewImage(index)}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          background: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "24px",
                          height: "24px",
                          cursor: "pointer",
                        }}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Image
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleNewImageUpload}
                    multiple
                  />
                </Button>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={formik.handleSubmit}
                >
                  {isSubmitting ? "Updating..." : "Update"}
                </Button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default EditLot;
